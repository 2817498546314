"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = setContents;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _App = _interopRequireDefault(require("./components/App"));
var _titles = _interopRequireDefault(require("./config/constants/titles"));
var _routes = _interopRequireDefault(require("./config/constants/routes"));
function setContents() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var wapp = p.wapp;
  function getTitle(_ref) {
    var wapp = _ref.wapp,
      res = _ref.res,
      title = _ref.title;
    var config = wapp.getTargetObject().config;
    var _config$siteName = config.siteName,
      siteName = _config$siteName === void 0 ? "Wapplr" : _config$siteName;
    var _res$wappResponse = res.wappResponse,
      statusCode = _res$wappResponse.statusCode,
      statusMessage = _res$wappResponse.statusMessage,
      errorMessage = _res$wappResponse.errorMessage;
    if (statusCode === 404) {
      title = statusMessage || "Not found";
    }
    if (statusCode === 500) {
      title = errorMessage || statusMessage || "Internal Server Error";
    }
    return title + " | " + siteName;
  }
  wapp.contents.add({
    home: {
      render: _App["default"],
      renderType: "react",
      title: function title(p) {
        return getTitle((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
          title: _titles["default"].homeTitle
        }));
      }
    }
  });
  wapp.router.replace([{
    path: "/",
    contentName: "home"
  }]);
  wapp.contents.add({
    models: {
      render: _App["default"],
      renderType: "react",
      title: function title(p) {
        return getTitle((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
          title: _titles["default"].modelsTitle
        }));
      }
    }
  });
  wapp.router.add([{
    path: _routes["default"].modelsRoute,
    contentName: "models"
  }]);
  wapp.contents.add({
    collections: {
      render: _App["default"],
      renderType: "react",
      title: function title(p) {
        return getTitle((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
          title: _titles["default"].collectionsTitle
        }));
      }
    }
  });
  wapp.router.add([{
    path: _routes["default"].modelsRoute + "/:model" + _routes["default"].collectionsRoute,
    contentName: "collections"
  }, {
    path: _routes["default"].modelsRoute + "/:model" + "/:step/:question",
    contentName: "collections"
  }]);
  wapp.contents.add({
    summary: {
      render: _App["default"],
      renderType: "react",
      title: function title(p) {
        return getTitle((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
          title: _titles["default"].summaryTitle
        }));
      }
    }
  });
  wapp.router.add([{
    path: _routes["default"].summaryRoute,
    contentName: "summary"
  }]);
}