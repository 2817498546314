"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = makeMaterialStyle;
function makeMaterialStyle(theme) {
  return {
    summary: {
      "& $listItem": {
        color: theme.palette.text.primary
      },
      "& $listItemIcon": {
        color: theme.palette.text.primary
      }
    },
    listItem: {},
    listItemIcon: {}
  };
}